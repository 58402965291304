import React from "react"
import tw from "twin.macro"
import StayConnectedBannerIh from "@components/StayConnectedBannerIh"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHeroIh from "@components/staticHeroIh"
import Section from "@components/section"
import Container from "@components/container"
import { headingIh, sectionIh } from "@styles/ui"
import FindADoctorWidget from "@components/FindADoctor"

const FindADoctor = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        title="Treating IH Starts With Talking To A Sleep Specialist | XYWAV® IH"
        description="Find treatment for your Idiopathic Hypersomnia (IH); locate a sleep specialist to help find a treatment plan."
        location={location}
      />
      <StaticHeroIh
        heading="Find a provider near you"
        subheading="Treating adults with Idiopathic Hypersomnia (IH) starts with talking to a provider about XYWAV"
      />
      <Section addedStyles={sectionIh}>
        <Container isIh>
          <h2 css={[headingIh, tw`lg:max-w-[62rem]`]}>
            Finding a provider to help you build a treatment plan that is right
            for you may make all the difference.
          </h2>
        </Container>
      </Section>

      <FindADoctorWidget location={location} />

      <Section addedStyles={tw`py-8 z-[-1] relative xl:py-8`}>
        <Container isIh>
          <p tw="text-base font-[350]">
            Inclusion of these healthcare providers in this directory does not
            imply their endorsement, referral, or recommendation of Jazz
            products. Likewise, inclusion in this directory does not represent
            an endorsement, referral, or recommendation by Jazz of these
            healthcare providers. Jazz does not supervise the medical care
            furnished through these providers. No fees have been exchanged for
            inclusion in this database. This list may not be complete. This list
            does not guarantee accuracy. Whom you select as your healthcare
            provider is your sole responsibility. This decision should be made
            with great care and in consultation with a trusted healthcare
            provider. Jazz is not liable to you or others for any decisions made
            in consideration of the information received from this site.
          </p>
        </Container>
      </Section>

      <StayConnectedBannerIh />
    </Layout>
  )
}

export default FindADoctor
